import { SynapsConfig, SynapsInit, SynapsMessages } from "./types/sdkTypes";
import { Loader } from "./utils/loader";

const DEFAULT_CONTAINER_ID = "synaps-wrapper";
const DEFAULT_CORPORATE_URL = "https://verify-corporate.synaps.io";
const DEFAULT_INDIVIDUAL_URL = "https://verify.synaps.io";

let verifyIframe: HTMLIFrameElement | null = null;
let synapsInitConfig: SynapsInit | null = null;

const SynapsVerifyConfig: SynapsConfig = {
  verifyUrl: DEFAULT_INDIVIDUAL_URL,
};

const getContainer = (mode: "modal" | "embed", containerId: string) => {
  if (mode === "modal") {
    let container = document.getElementById(containerId);

    if (!container) {
      container = document.createElement("div");
      container.id = containerId || DEFAULT_CONTAINER_ID;
      document.body.appendChild(container);
    }

    return container;
  }

  return document.getElementById(containerId || DEFAULT_CONTAINER_ID) || null;
};

let onFinishRef: (() => void) | null = null;
let onCloseRef: (() => void) | null = null;
let handleSignRef: ((payload: string) => Promise<string>) | null = null;
const init = (initConfig: SynapsInit) => {
  // Destructure initConfig with default values
  const {
    containerId = DEFAULT_CONTAINER_ID,
    onFinish,
    onClose,
    handleSign,
    mode = "modal",
    service = "individual",
  } = initConfig;

  onFinishRef = onFinish || null;
  onCloseRef = onClose || null;
  handleSignRef = handleSign || null;

  // Set the verify URL based on the service
  if (service === "corporate") {
    SynapsVerifyConfig.verifyUrl = DEFAULT_CORPORATE_URL;
  } else if (SynapsVerifyConfig.verifyUrl === DEFAULT_CORPORATE_URL) {
    SynapsVerifyConfig.verifyUrl = DEFAULT_INDIVIDUAL_URL;
  }

  // Get or create the container
  const container = getContainer(mode, containerId);

  if (!container) {
    console.error(`No element found with id ${containerId}`);
    return;
  }

  // Remove an existing verifyIframe if it exists
  const iframeContainer = container.querySelector("iframe");
  if (iframeContainer) {
    container.removeChild(iframeContainer);
    iframeContainer.remove();
  }

  // Initialize the loader's CSS
  Loader.init();

  // Initialize event listeners
  initEventListeners();

  // Create an iframe if the mode is "embed"
  if (mode === "embed") createIframe(initConfig);

  // Save the config in a variable for reference
  synapsInitConfig = initConfig;
};

const createIframe = (initConfig: SynapsInit) => {
  const {
    containerId,
    sessionId,
    lang,
    mode = "modal",
    walletAddress,
    withFinishButton,
    tier,
    service,
    hideReuse = false,
  } = initConfig;

  const container = getContainer(mode, containerId || DEFAULT_CONTAINER_ID);

  if (!container) {
    console.error(`No element found with id ${containerId}`);
    return;
  }

  const iframeContainer = container.querySelector("iframe");
  if (iframeContainer) return;

  Loader.show(mode === "modal" ? document.body : container, mode);

  const iframeUrl = new URL(SynapsVerifyConfig.verifyUrl);

  if (service === "corporate") {
    iframeUrl.searchParams.append("service", service);
    if (hideReuse) iframeUrl.searchParams.append("hide_reuse", "true")
  }

  iframeUrl.searchParams.append("session_id", sessionId);
  iframeUrl.searchParams.append("type", mode);
  iframeUrl.searchParams.append("sem_ver", "4.0.45");
  if (lang) iframeUrl.searchParams.append("lang", lang);
  if (walletAddress)
    iframeUrl.searchParams.append("wallet_address", walletAddress);
  if (withFinishButton)
    iframeUrl.searchParams.append("with_finish_button", "true");
  if (tier) iframeUrl.searchParams.append("tier", tier);

  const iframe = document.createElement("iframe");
  iframe.src = iframeUrl.href;
  // @ts-ignore
  iframe.allowtransparency = true;
  iframe.allowFullscreen = true;
  iframe.allow = "camera; microphone; midi; encrypted-media; clipboard-write;";

  if (mode === "modal") {
    iframe.style.position = "fixed";
    iframe.style.left = "0";
    iframe.style.top = "0";
  }

  iframe.style.width = "100%";
  iframe.style.height = "100%";
  iframe.style.opacity = "0";
  iframe.style.zIndex = "-1";
  iframe.style.border = "none";
  iframe.style.overflow = "hidden";
  iframe.style.backgroundColor = "transparent";
  iframe.style.background = "transparent";
  iframe.style.transition = "opacity 0.15s ease-in-out";

  verifyIframe = iframe;

  container.appendChild(iframe);
};

const config = ({ verifyUrl }: Partial<SynapsConfig>) => {
  if (verifyUrl) SynapsVerifyConfig.verifyUrl = verifyUrl;
};

const hide = () => {
  if (!verifyIframe) {
    console.error("No iframe found");
    return;
  }

  // Hide loader
  Loader.hide();

  verifyIframe.style.zIndex = "-1";
  verifyIframe.style.opacity = "0";
};

const show = () => {
  const { mode = "modal" } = synapsInitConfig || {};
  if (mode === "modal" && synapsInitConfig) {
    createIframe(synapsInitConfig);
  }

  if (!verifyIframe) {
    console.error("No iframe found");
    return;
  }

  verifyIframe.style.opacity = "1";
  verifyIframe.style.zIndex = "999";
};

let eventListenerInit = false;
const initEventListeners = () => {
  if (eventListenerInit) return;
  eventListenerInit = true;

  const messageListener = (event: MessageEvent<SynapsMessages>) => {
    const originWithoutTrailingSlash = event.origin.replace(/\/$/, "");
    const verifyUrlWithoutTrailingSlash = SynapsVerifyConfig.verifyUrl.replace(
      /\/$/,
      ""
    );

    // Check if the event's origin matches the expected verify URL
    if (originWithoutTrailingSlash !== verifyUrlWithoutTrailingSlash) {
      return;
    }
    event.stopPropagation();

    const { type, data } = event.data;
    const { mode = "modal" } = synapsInitConfig || {};

    switch (type) {
      case "ready":
        // Hide the loader when the iframe is ready
        Loader.hide();

        if (synapsInitConfig?.mode === "embed" && verifyIframe) {
          verifyIframe.style.opacity = "1";
          verifyIframe.style.zIndex = "999";
        }
        break;

      case "request_sign":
        // Handle signing requests
        if (handleSignRef) {
          handleSignRef(data.payload).then((signature) => {
            // Send the signature to the iframe
            verifyIframe?.contentWindow?.postMessage(
              { type: "sign", data: { signature } },
              verifyUrlWithoutTrailingSlash
            );
          });
        }
        break;

      case "update":
        // Send an update message to the iframe
        verifyIframe?.contentWindow?.postMessage(
          { type: "update", data: {} },
          verifyUrlWithoutTrailingSlash
        );
        break;

      case "finish":
        // Handle finish events
        if (mode === "modal") {
          hide();
        }

        if (onFinishRef) {
          onFinishRef();
        }
        break;
      case "close":
        // Handle close events
        if (mode === "modal") {
          hide();
        }

        if (onCloseRef) {
          onCloseRef();
        }
        break;

      default:
        // Handle other event types if necessary
        break;
    }
  };

  // Add the message listener to the window
  window.addEventListener("message", messageListener);

  // Return a cleanup function to remove the event listener
  return () => {
    window.removeEventListener("message", messageListener);
  };
};

export const Synaps = {
  init,
  config,
  show,
  hide,
};
